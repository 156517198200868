<script setup lang="ts">
import dayjs from "dayjs";

const { t } = useT();
const isGuest = useIsGuest();
const { open } = useAppModals();
const prepareImgUrl = useImage();
const { piggyData, isFullStatus, readyToBreak, handlePiggyClick, refreshPiggyData } = usePiggy({ open });

const title = computed(() => (isFullStatus.value ? t("Break Your Piggy Bank!") : t("Piggy bank")));

const subTitle = computed(() =>
	isFullStatus.value ? t("It's full - you can collect free SC!") : t("Get Extra Coins, Easy!")
);

const buttonText = computed(() => {
	if (isGuest.value) {
		return t("Try now");
	}
	return readyToBreak.value
		? t("Collect Now for $", { msg: piggyData.value?.promoOffersPresets?.money ?? 0 })
		: t("Play & Feed Piggy");
});

const handleClick = () => {
	if (isGuest.value) {
		open("LazyOModalSignup");
		return;
	}

	handlePiggyClick();
};

const { durationLeft, reset } = useCountdown({
	timestamp: piggyData.value?.availableTimeToBreak || "",
	formatToken:
		dayjs(piggyData.value?.availableTimeToBreak ?? "").diff(dayjs(), "d") >= 1
			? "DD[ d ] HH[ h ]mm[ m ]"
			: "HH[ h ]mm[ m ]ss[ s ]",
	isOptimized: true,
	onCountdownStop: refreshPiggyData
});

const piggyBankImg = `url(${prepareImgUrl(`/nuxt-img/piggy-bank/banner/piggy-bank.jpg`, {
	format: "avif"
})})`;

const piggyBankImgLandscape = `url(${prepareImgUrl(`/nuxt-img/piggy-bank/banner/piggy-bank-landscape.jpg`, {
	format: "avif"
})})`;

watch(
	() => piggyData.value?.availableTimeToBreak,
	(value) => {
		if (value) {
			reset(value);
		}
	}
);
</script>

<template>
	<div class="wrapper">
		<picture class="image">
			<source
				:srcset="
					prepareImgUrl('/nuxt-img/piggy-bank/banner/shine-landscape.png', {
						format: 'avif',
						width: 80,
						height: 75,
						loading: 'lazy'
					})
				"
				media="(max-width: 1024px) and (orientation: landscape)"
			/>
			<NuxtImg src="/nuxt-img/piggy-bank/banner/shine.png" format="avif" width="328" alt="prize" loading="lazy" />
		</picture>
		<NuxtImg width="73" height="49" src="/nuxt-img/piggy-bank/banner/coin-1.png" class="coin coin-1" alt="coin" />
		<NuxtImg width="43" height="33" src="/nuxt-img/piggy-bank/banner/coin-2.png" class="coin coin-2" alt="coin" />
		<div class="content">
			<AText variant="toledo">
				{{ title }}
			</AText>

			<AText variant="tanzay" :modifiers="['bold']">
				{{ subTitle }}
			</AText>

			<template v-if="!isGuest">
				<MPrizeFund v-if="isFullStatus" variant="entries" icon="12/secret-coins" :icon-size="32">
					<AText :size="32" as="h4" :modifiers="['bold']">
						{{ numberFormat(piggyData?.maxAmount ?? 0) }}
					</AText>
					<AText :size="10" :modifiers="['semibold', 'uppercase']">
						Free <br />
						super coins
					</AText>
				</MPrizeFund>
				<MPiggyBankProgressBar
					v-else
					class="progress"
					:complete="piggyData?.moneyBoxAmount ?? 0"
					:goal="piggyData?.maxAmount ?? 0"
				/>
			</template>

			<AText
				v-if="readyToBreak && piggyData?.availableTimeToBreak"
				:size="14"
				class="text-constanta"
				:modifiers="['center']"
			>
				Break in
				<br />
				<AText :size="16" class="text-сirebon" :modifiers="['semibold']">{{ durationLeft }}</AText>
			</AText>
			<AButton v-else variant="primary" :modifiers="['wide']" size="lg" @click="handleClick">
				<AText variant="toledo" :modifiers="['medium']">
					{{ buttonText }}
				</AText>
			</AButton>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.wrapper {
	border-radius: 24px;
	background: var(--gujrat);
	width: 328px;
	padding: 2px;
	height: 100%;
	position: relative;
	overflow: hidden;

	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			height: 458px;
			width: 100%;
		}
	}

	@include media-breakpoint-down(md) {
		height: 458px;
		width: 100%;
	}

	.image {
		position: absolute;
		right: 0;
		top: 0;
		animation: neon 2s ease-in-out 1s infinite;
	}

	.coin {
		position: absolute;

		&-1 {
			right: 120px;
			animation: levitation 2s ease-in-out 1s infinite;
		}

		&-2 {
			right: 60px;
			animation: swingAndScale 2s linear 1s infinite;
		}
	}

	.progress,
	button {
		margin-top: 12px;
	}

	.content {
		width: 100%;
		height: 100%;
		border-radius: 24px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 22px 14px;
		background-image: v-bind(piggyBankImg);
		background-repeat: no-repeat;
		background-size: cover;
		gap: 4px;

		@include media-breakpoint-down(lg) {
			@media (orientation: landscape) {
				background-image: v-bind(piggyBankImgLandscape);
				background-position: 75% 50%;
			}
		}
	}
}

@keyframes swingAndScale {
	0%,
	100% {
		transform: scale(1);
	}

	25% {
		transform: scale(1.2);
	}

	50% {
		transform: rotate(-15deg);
	}

	75% {
		transform: rotate(15deg);
	}
}

@keyframes levitation {
	0% {
		top: 0;
	}

	50% {
		top: -10px;
	}

	100% {
		top: 0;
	}
}

@keyframes neon {
	20% {
		opacity: 1;
	}
	80% {
		opacity: 0.1;
	}
}
</style>
